<template>
  <div class="InvitationList">
    <div class="InvitationList-content">
      <div class="InvitationList-list">
        <div
          class="InvitationList-item"
          v-for="(item, index) in list"
          :key="index"
        >
          <div>
            <div class="email">{{ item.email }}</div>
            <div class="time">
              {{
                dayjs(+new Date(item.createTime)).format("YYYY-MM-DD HH:mm:ss")
              }}
            </div>
          </div>
          <div :class="['username']">
            <div :class="{ success: item.authStatus == 1 }">
              {{ statusObj[item.authStatus] || this.$t("暫未通過") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyInviteList } from "@/api/user.js";
import dayjs from "dayjs";
import i18n from "@/i18n";
import Mixins from "@/utils/mixins.js";
export default {
  mixins: [Mixins],
  data() {
    return {
      list: [],
      statusObj: {},
      dayjs: dayjs
    };
  },
  created() {

    const langEnv = this.$route.query.langEnv;
    this.handleChangeLang(langEnv, i18n);
    this.getList();
    this.statusObj["0"] = this.$t("暫未通過");
    this.statusObj["1"] = this.$t("已成功");
    this.statusObj["2"] = this.$t("暫未通過");
  },
  mounted() {
    document.title = this.$t("邀請記錄");
  },
  methods: {
    async getList() {
      const langEnv = this.$route.query.langEnv;
      const res = await getMyInviteList({}, langEnv);
      this.list = res.data;
    }
  }
};
</script>

<style lang="scss">
.InvitationList-content {
  padding-top: 12px;
  .InvitationList-list {
    padding: 16px;
    .InvitationList-item {
      border-bottom: 1px solid #f8f8f8;
      padding: 16px;
      margin-bottom: 16px;
      border-radius: 12px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .email {
        font-size: 16px;
        color: #1a1a1a;
      }
      .time {
        font-size: 12px;
        color: #999999;
      }
      .username {
        margin-top: 6px;
        font-weight: bold;
        font-size: 16px;
        color: #1a1a1a;
      }
    }
  }
}
.success {
  color: #90d12e;
}
</style>
